import {formatDate} from '@thx/date';
import debug from 'debug';
import {Link, useParams} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes as administrationRoutes} from '../../../administration/routes';
import {routes} from '../../routes';
import {useGetTimeCardDatesQuery} from './getTimeCardDates';

const d = debug('tacs.web.user.components.UserActivity.UserActivityButton');

export function UserActivityButton() {
	const throwError = useAsyncError();
	const {userId, timeCardId} = useParams<typeof administrationRoutes.types.timeCardEdit>();
	const {data, error, loading} = useGetTimeCardDatesQuery({variables: {id: timeCardId || ''}});

	if (error) throwError(error);

	if (loading || !data?.getTimeCardById) {
		return (
			<Button fluid color="blue" compact loading>
				Activity for Pay Period
			</Button>
		);
	}

	return (
		<Link
			to={routes.to.userActivity({
				userId,
				startDate: formatDate(data.getTimeCardById.payPeriodStart, {format: 'yyyy-MM-d'}),
				endDate: formatDate(data.getTimeCardById.payPeriodEnd, {format: 'yyyy-MM-d'}),
			})}
		>
			<Button fluid color="blue" compact>
				Activity for Pay Period
			</Button>
		</Link>
	);
}
