import {LocalDate} from '@js-joda/core';
import {LocalDatePicker, MoneyInput, useTForm} from '@thx/controls';
import {toMoney} from '@thx/money';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../routes';
import {useChangeHourlyPayByUserIdMutation} from './changeHourlyPayByUserId';
import {useGetHourlyPayQuery} from './getHourlyPay';

export function ChangeHourlyPayButton() {
	const throwError = useAsyncError();
	const {userId} = useParams<typeof routes.types.timeCards>();
	const [editClicked, setEditClicked] = useState(false);
	const [editMutation, {loading: mutationLoading}] = useChangeHourlyPayByUserIdMutation();
	const {data, loading, error} = useGetHourlyPayQuery({variables: {userId}});

	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm({
		initialValues: {
			startingDate: LocalDate.now(),
			hourlyPay: data?.getLatestTimeCard?.timeCardLines
				? data.getLatestTimeCard.timeCardLines[data.getLatestTimeCard.timeCardLines.length - 1].hourlyPay
				: toMoney(0),
		},
		onSubmit: formValues => {
			editMutation({
				variables: {userId, hourlyPay: formValues.hourlyPay, startingDate: formValues.startingDate},
				refetchQueries: ['getTimeCardById', 'getTimeCardStats', 'getTimeCards'],
			})
				.then(async () => {
					toast.success('Hourly Pay Changed');
					setEditClicked(false);
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});

	if (error) throw error;

	if (editClicked) {
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<Form.Input autoFocus label="Starting Date">
					<LocalDatePicker value={values.startingDate} onChange={val => setFieldValue('startingDate', val)} />
				</Form.Input>
				<Form.Input label="Hourly Pay">
					<MoneyInput value={values.hourlyPay} onChange={val => setFieldValue('hourlyPay', val)} />
				</Form.Input>
				<Button disabled={mutationLoading} positive type="submit" floated="right">
					Save
				</Button>
				<Button
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid compact color="blue" onClick={() => setEditClicked(true)} disabled={!data?.getLatestTimeCard}>
			Change Hourly Pay
		</Button>
	);
}
