import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editAgentOrganization.graphql';

const defaultOptions = {} as const;
export type EditAgentOrganizationMutationVariables = Types.Exact<{
	data: Types.EditAgentInput;
}>;

export type EditAgentOrganizationMutation = {editAgent?: {id: string} | null};

export type EditAgentOrganizationType = {id: string};

export type EditAgentOrganizationMutationFn = Apollo.MutationFunction<EditAgentOrganizationMutation, EditAgentOrganizationMutationVariables>;

/**
 * __useEditAgentOrganizationMutation__
 *
 * To run a mutation, you first call `useEditAgentOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAgentOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAgentOrganizationMutation, { data, loading, error }] = useEditAgentOrganizationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditAgentOrganizationMutation(
	baseOptions?: Apollo.MutationHookOptions<EditAgentOrganizationMutation, EditAgentOrganizationMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditAgentOrganizationMutation, EditAgentOrganizationMutationVariables>(Operations, options);
}
export type EditAgentOrganizationMutationHookResult = ReturnType<typeof useEditAgentOrganizationMutation>;
export type EditAgentOrganizationMutationResult = Apollo.MutationResult<EditAgentOrganizationMutation>;
export type EditAgentOrganizationMutationOptions = Apollo.BaseMutationOptions<EditAgentOrganizationMutation, EditAgentOrganizationMutationVariables>;
