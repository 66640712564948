import {useAuthenticatedState} from '@imperium/auth-client';
import {formatDate} from '@thx/date';
import {env} from '@thx/env';
import debug from 'debug';
import {useState} from 'react';
import {Divider, Feed, Icon, Message, SemanticCOLORS} from 'semantic-ui-react';
import {CommunicationTypeEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {useGetCurrentAgentDetailsQuery} from '../../accounts/components/accounts/accountCreate/AccountForm/getCurrentAgentDetails';
import {defaults} from '../../defaults';
import type {GetCommunicationFeedItemsType} from './CommunicationFeed/getCommunicationFeedItems';
import {usePinCommunicationFeedItemMutation} from './CommunicationFeed/pinCommunicationFeedItem';
import {CommunicationFeedItemForm} from './CommunicationFeedItemForm';
import {description, icon} from './communicationDisplay';

const d = debug('tacs.web.communications.components.CommunicationFeedItem');

type CommunicationFeedItemProps = {
	entry: GetCommunicationFeedItemsType;
};

export function CommunicationFeedItem({entry}: CommunicationFeedItemProps): JSX.Element {
	const throwError = useAsyncError();
	const auth = useAuthenticatedState();
	const [editMode, setEditMode] = useState(false);
	const systemAgentId = env.getString('systemAgentId', defaults.systemAgentId);

	const [pinCommunicationFeedItemMutation] = usePinCommunicationFeedItemMutation();

	const {data, error} = useGetCurrentAgentDetailsQuery({variables: {userId: auth.id || ''}});
	if (error) throw error;

	const pinCommunicationFeedItem = () => {
		if (!entry?.id) return;
		pinCommunicationFeedItemMutation({
			variables: {
				id: entry.id,
				version: entry.version || 0,
			},
			refetchQueries: ['getCommunicationFeedItems'],
		}).catch(throwError);
	};

	const isEditable = (): boolean => {
		return entry.createdBy.id === (data?.getAgentByUserId?.id || '') || entry.createdBy.id === systemAgentId;
	};

	// format content
	function formatContent() {
		let contents = entry.contents || '';
		if (entry.type === CommunicationTypeEnum.Email && entry.data?.subject) {
			let emailOutput = '';
			if (entry.data?.subject) emailOutput += `Subject: ${entry.data.subject}\n`;
			if (entry.data?.cc) emailOutput += `CC: ${entry.data.cc.toString()} \n\n`;
			if (entry?.data?.text) emailOutput += `${entry.data.text} \n`;
			if (contents) emailOutput += `\nNote:\n${contents}`;
			contents = emailOutput;
		}
		if (entry.type === 'Call' && entry.contents === '') return true;
		return <div>{contents}</div>;
	}

	if (editMode && isEditable())
		return (
			<CommunicationFeedItemForm
				entry={entry}
				type={entry.type || CommunicationTypeEnum.Note}
				contents={entry.contents}
				isEditForm={() => {
					setEditMode(false);
				}}
			/>
		);

	return (
		<>
			<Feed.Event id={entry.id}>
				<Feed.Label>
					<Icon
						color={entry.color as SemanticCOLORS}
						name={icon(entry.type)}
						style={{cursor: isEditable() ? 'pointer' : undefined}}
						onClick={() => setEditMode(true)}
					/>
				</Feed.Label>
				<Feed.Content>
					<Feed.Date>
						<Feed.Summary style={{cursor: isEditable() ? 'pointer' : undefined}} onClick={() => setEditMode(true)}>
							{entry.type === CommunicationTypeEnum.Call
								? `Phone call on ${formatDate(entry.createdAt, {format: 'MMMM d, yyyy - HH:mm', time: true})}`
								: `${entry.createdBy?.name || entry.data?.from} ${description(entry.type)} on ${formatDate(entry.createdAt, {
										format: 'MMMM d, yyyy - HH:mm',
										time: true,
								  })}`}
						</Feed.Summary>
					</Feed.Date>
					<Message
						color={entry.color as SemanticCOLORS}
						style={{cursor: isEditable() ? 'pointer' : undefined, whiteSpace: 'pre-line'}}
						onClick={() => setEditMode(true)}
					>
						<Feed.Extra style={{marginTop: 0, wordBreak: 'break-word'}}>{formatContent()}</Feed.Extra>
					</Message>
				</Feed.Content>
				<Feed.Meta style={{float: 'right', paddingLeft: '10px'}}>
					<Icon
						color={entry.color as SemanticCOLORS}
						name="pin"
						size="large"
						style={{cursor: 'pointer'}}
						rotated={!entry.pinned ? 'clockwise' : undefined}
						onClick={pinCommunicationFeedItem}
						className="noPrint"
					/>
				</Feed.Meta>
			</Feed.Event>
			<Divider />
		</>
	);
}
