import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import LogBookUploader from './components/LogBookUploader';
import {DocumentSidebarTabs} from './components/documents/DocumentSidebarTabs';
import {DocumentsTableSidebar} from './components/documents/DocumentsTableSidebar';
import {FileViewerSidebar} from './components/receivedFiles/FileViewerSidebar';
import {StatementCreate} from './components/statement/StatementCreateForm';
import {StatementEdit} from './components/statement/StatementEditForm';
import {routes} from './routes';

const AddExpectedDocumentExceptionForm = loadable(() => import('./components/expectedDocuments/AddExpectedDocumentExceptionForm'));
const AddExpectedDocumentForm = loadable(() => import('./components/expectedDocuments/AddExpectedDocumentForm'));
const DocumentSorter = loadable(() => import('./components/draftDocuments/DocumentSorter'));
const FileUploader = loadable(() => import('./components/FileUploader'));
const EditExpectedDocumentExceptionForm = loadable(() => import('./components/expectedDocuments/EditExpectedDocumentExceptionForm'));
const EditExpectedDocumentForm = loadable(() => import('./components/expectedDocuments/EditExpectedDocumentForm'));
const ViewExpectedDocumentsTable = loadable(() => import('./components/expectedDocuments/ViewExpectedDocumentsTable'));
const FileViewer = loadable(() => import('./components/receivedFiles/FileViewer'));
const MissingDocumentsReport = loadable(() => import('./components/expectedDocuments/MissingDocumentsReport'));
const MissingPaperworkEmailList = loadable(() => import('./components/expectedDocuments/MissingPaperworkEmailList'));
const ReceivedFilesTable = loadable(() => import('./components/receivedFiles/ReceivedFilesTable/ReceivedFilesTable'));
const DocumentsTable = loadable(() => import('./components/documents/DocumentsTable'));
const DocumentViewer = loadable(() => import('./components/documents/DocumentViewer'));
const DraftDocumentsTable = loadable(() => import('./components/draftDocuments/DraftDocumentsTable/DraftDocumentsTable'));

export const routeProps = createPages(routes, {
	documents: {
		header: 'Documents',
		content: () => <DocumentsTable />,
		sidebar: [{render: DocumentsTableSidebar}],
	},
	documentSorter: {
		content: () => <DocumentSorter />,
	},
	documentUpload: {
		header: 'Upload Documents',
		content: () => <FileUploader />,
	},
	logBookUpload: {
		header: 'Upload Log Books',
		content: () => <LogBookUploader />,
	},
	documentViewer: {
		content: () => <DocumentViewer />,
		sidebar: [
			{
				render: DocumentSidebarTabs,
			},
		],
	},
	receivedFiles: {
		header: 'Packages',
		content: () => <ReceivedFilesTable />,
	},
	fileViewer: {
		content: () => <FileViewer />,
		sidebar: [{render: FileViewerSidebar}],
	},
	draftDocuments: {
		header: 'Unsorted Files',
		content: () => <DraftDocumentsTable />,
	},
	viewExpectedDocumentSettings: {
		header: 'Expected Documents',
		content: () => <ViewExpectedDocumentsTable />,
		sidebar: [
			{
				to: dat => routes.to.addExpectedDocument({accountInfoId: dat.params.accountInfoId}),
				color: 'green',
				text: 'Add Expected Document',
			},
		],
	},
	addExpectedDocument: {
		header: 'Add Expected Document',
		content: () => <AddExpectedDocumentForm />,
	},
	editExpectedDocument: {
		header: 'Edit Expected Document',
		content: () => <EditExpectedDocumentForm />,
	},
	missingDocuments: {
		header: 'Missing Documents',
		content: () => <MissingDocumentsReport />,
		sidebar: [
			{
				to: dat => routes.to.addExpectedDocument({accountInfoId: dat.params.accountInfoId}),
				color: 'green',
				text: 'Add Expected Document',
			},
			{
				to: dat => routes.to.viewExpectedDocumentSettings({accountInfoId: dat.params.accountInfoId}),
				color: 'blue',
				text: 'Manage Expected Documents',
			},
			{
				to: dat => routes.to.missingPaperworkEmail({accountInfoId: dat.params.accountInfoId}),
				color: 'purple',
				text: 'Email Missing Paperwork List',
			},
		],
	},
	missingPaperworkEmail: {
		header: 'Missing Paperwork Email',
		content: () => <MissingPaperworkEmailList />,
	},
	addExpectedDocumentException: {
		header: 'Grant Expected Document ',
		content: () => <AddExpectedDocumentExceptionForm />,
	},
	editExpectedDocumentException: {
		header: 'Edit Expected Document Exception',
		content: () => <EditExpectedDocumentExceptionForm />,
	},
	statementCreate: {
		header: 'Statement Create',
		content: () => <StatementCreate />,
	},
	statementEdit: {
		header: 'Statement Edit',
		content: () => <StatementEdit />,
	},
});
