import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getCorporateAccountInformation.graphql';

const defaultOptions = {} as const;
export type GetCorporateAccountInformationQueryVariables = Types.Exact<{
	id: Types.Scalars['String'];
	currentlyLoggedInUserId: Types.Scalars['String'];
}>;

export type GetCorporateAccountInformationQuery = {
	getCorporateAccountByAccountInfoId?: {
		id: string;
		version: number;
		yearEnd?: LocalDate | null;
		gstFilingFrequency: Types.GstFilingFrequencyEnum;
		communicationCode?: string | null;
		businessNumber?: string | null;
		incorporationDate?: LocalDate | null;
		directors?: Array<{
			id: string;
			version: number;
			office: Types.DirectorOfficeEnum;
			dateElected: LocalDate;
			dateSteppedDown?: LocalDate | null;
			contact: {id: string; version: number; givenName: string; surname: string; preferredName?: string | null};
		}> | null;
		accountInfo: {
			id: string;
			version: number;
			name: string;
			friendlyId: number;
			archived: boolean;
			address?: {
				id: string;
				version: number;
				lineOne?: string | null;
				lineTwo?: string | null;
				city?: string | null;
				province?: string | null;
				postalCode?: string | null;
				country?: string | null;
			} | null;
			manager?: {id: string; version: number; name: string} | null;
			defaultAgent?: {id: string; version: number; name: string} | null;
			organization?: {id: string; name: string} | null;
			office?: {id: string; name: string} | null;
		};
	} | null;
	getAgentByUserId?: {id: string; version: number; roles: Array<Types.AgentRole>} | null;
};

export type GetCorporateAccountInformationType = {
	id: string;
	version: number;
	yearEnd?: LocalDate | null;
	gstFilingFrequency: Types.GstFilingFrequencyEnum;
	communicationCode?: string | null;
	businessNumber?: string | null;
	incorporationDate?: LocalDate | null;
	directors?: Array<{
		id: string;
		version: number;
		office: Types.DirectorOfficeEnum;
		dateElected: LocalDate;
		dateSteppedDown?: LocalDate | null;
		contact: {id: string; version: number; givenName: string; surname: string; preferredName?: string | null};
	}> | null;
	accountInfo: {
		id: string;
		version: number;
		name: string;
		friendlyId: number;
		archived: boolean;
		address?: {
			id: string;
			version: number;
			lineOne?: string | null;
			lineTwo?: string | null;
			city?: string | null;
			province?: string | null;
			postalCode?: string | null;
			country?: string | null;
		} | null;
		manager?: {id: string; version: number; name: string} | null;
		defaultAgent?: {id: string; version: number; name: string} | null;
		organization?: {id: string; name: string} | null;
		office?: {id: string; name: string} | null;
	};
};

/**
 * __useGetCorporateAccountInformationQuery__
 *
 * To run a query within a React component, call `useGetCorporateAccountInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCorporateAccountInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorporateAccountInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      currentlyLoggedInUserId: // value for 'currentlyLoggedInUserId'
 *   },
 * });
 */
export function useGetCorporateAccountInformationQuery(
	baseOptions: Apollo.QueryHookOptions<GetCorporateAccountInformationQuery, GetCorporateAccountInformationQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetCorporateAccountInformationQuery, GetCorporateAccountInformationQueryVariables>(Operations, options);
}
export function useGetCorporateAccountInformationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetCorporateAccountInformationQuery, GetCorporateAccountInformationQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetCorporateAccountInformationQuery, GetCorporateAccountInformationQueryVariables>(Operations, options);
}
export type GetCorporateAccountInformationQueryHookResult = ReturnType<typeof useGetCorporateAccountInformationQuery>;
export type GetCorporateAccountInformationLazyQueryHookResult = ReturnType<typeof useGetCorporateAccountInformationLazyQuery>;
export type GetCorporateAccountInformationQueryResult = Apollo.QueryResult<
	GetCorporateAccountInformationQuery,
	GetCorporateAccountInformationQueryVariables
>;
