import {useCan} from '@imperium/auth-client';
import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {AccountTypeEnum, Permission} from '~core/graphql';
import {isLoggedIn, needsPermission} from '~lib/permissionHelpers';
import {useAccountState} from '../accounts/state';
import {routes as assetRoutes} from './routes';

const d = debug('tacs.web.assets.layout');

export const layout: LayoutData = {
	permissions: [Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount],
	primaryMenu: [],
	secondaryMenu: [
		{
			text: 'Corporate Assets',
			icon: 'truck',
			stateSelectorHook: [useLayoutState, useAccountState],
			permissionSelectorHook: [
				data => {
					const [viewAdminPayrollAccount] = useCan([
						{permission: Permission.ViewAdminPayrollAccount, data: {accountInfoId: data?.state.accountInfoId}},
					]);
					return {viewAdminPayrollAccount};
				},
			],
			visible: {
				...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
				...isLoggedIn(),
				'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
			},
			to: data => (data.state.params?.accountInfoId ? assetRoutes.to.corporateAssets({accountInfoId: data.state.params.accountInfoId}) : ''),
			moveToKey: 'settings',
		},
		{
			text: 'Personal Vehicles',
			icon: 'car',
			visible: {
				...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
				...isLoggedIn(),
				'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
			},
			stateSelectorHook: [useLayoutState, useAccountState],
			to: data => (data.state.params?.accountInfoId ? assetRoutes.to.personalVehicles({accountInfoId: data.state.params.accountInfoId}) : ''),
			moveToKey: 'settings',
		},
	],
};
