import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getAgentRolesButtonAgent.graphql';

const defaultOptions = {} as const;
export type GetAgentRolesButtonAgentQueryVariables = Types.Exact<{
	userId: Types.Scalars['String'];
}>;

export type GetAgentRolesButtonAgentQuery = {getAgentByUserId?: {id: string; version: number; roles: Array<Types.AgentRole>} | null};

export type GetAgentRolesButtonAgentType = {id: string; version: number; roles: Array<Types.AgentRole>};

/**
 * __useGetAgentRolesButtonAgentQuery__
 *
 * To run a query within a React component, call `useGetAgentRolesButtonAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentRolesButtonAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentRolesButtonAgentQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAgentRolesButtonAgentQuery(
	baseOptions: Apollo.QueryHookOptions<GetAgentRolesButtonAgentQuery, GetAgentRolesButtonAgentQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetAgentRolesButtonAgentQuery, GetAgentRolesButtonAgentQueryVariables>(Operations, options);
}
export function useGetAgentRolesButtonAgentLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetAgentRolesButtonAgentQuery, GetAgentRolesButtonAgentQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetAgentRolesButtonAgentQuery, GetAgentRolesButtonAgentQueryVariables>(Operations, options);
}
export type GetAgentRolesButtonAgentQueryHookResult = ReturnType<typeof useGetAgentRolesButtonAgentQuery>;
export type GetAgentRolesButtonAgentLazyQueryHookResult = ReturnType<typeof useGetAgentRolesButtonAgentLazyQuery>;
export type GetAgentRolesButtonAgentQueryResult = Apollo.QueryResult<GetAgentRolesButtonAgentQuery, GetAgentRolesButtonAgentQueryVariables>;
