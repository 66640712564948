import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEditAgentSupervisor.graphql';

const defaultOptions = {} as const;
export type GetEditAgentSupervisorQueryVariables = Types.Exact<{
	userId: Types.Scalars['String'];
}>;

export type GetEditAgentSupervisorQuery = {getAgentByUserId?: {id: string; version: number; supervisor?: {id: string} | null} | null};

export type GetEditAgentSupervisorType = {id: string; version: number; supervisor?: {id: string} | null};

/**
 * __useGetEditAgentSupervisorQuery__
 *
 * To run a query within a React component, call `useGetEditAgentSupervisorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditAgentSupervisorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditAgentSupervisorQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetEditAgentSupervisorQuery(
	baseOptions: Apollo.QueryHookOptions<GetEditAgentSupervisorQuery, GetEditAgentSupervisorQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEditAgentSupervisorQuery, GetEditAgentSupervisorQueryVariables>(Operations, options);
}
export function useGetEditAgentSupervisorLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEditAgentSupervisorQuery, GetEditAgentSupervisorQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEditAgentSupervisorQuery, GetEditAgentSupervisorQueryVariables>(Operations, options);
}
export type GetEditAgentSupervisorQueryHookResult = ReturnType<typeof useGetEditAgentSupervisorQuery>;
export type GetEditAgentSupervisorLazyQueryHookResult = ReturnType<typeof useGetEditAgentSupervisorLazyQuery>;
export type GetEditAgentSupervisorQueryResult = Apollo.QueryResult<GetEditAgentSupervisorQuery, GetEditAgentSupervisorQueryVariables>;
