import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getCurrentAgentDetails.graphql';

const defaultOptions = {} as const;
export type GetCurrentAgentDetailsQueryVariables = Types.Exact<{
	userId: Types.Scalars['String'];
}>;

export type GetCurrentAgentDetailsQuery = {getAgentByUserId?: {id: string; organization?: {id: string} | null; office?: {id: string} | null} | null};

export type GetCurrentAgentDetailsType = {id: string; organization?: {id: string} | null; office?: {id: string} | null};

/**
 * __useGetCurrentAgentDetailsQuery__
 *
 * To run a query within a React component, call `useGetCurrentAgentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentAgentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentAgentDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCurrentAgentDetailsQuery(
	baseOptions: Apollo.QueryHookOptions<GetCurrentAgentDetailsQuery, GetCurrentAgentDetailsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetCurrentAgentDetailsQuery, GetCurrentAgentDetailsQueryVariables>(Operations, options);
}
export function useGetCurrentAgentDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentAgentDetailsQuery, GetCurrentAgentDetailsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetCurrentAgentDetailsQuery, GetCurrentAgentDetailsQueryVariables>(Operations, options);
}
export type GetCurrentAgentDetailsQueryHookResult = ReturnType<typeof useGetCurrentAgentDetailsQuery>;
export type GetCurrentAgentDetailsLazyQueryHookResult = ReturnType<typeof useGetCurrentAgentDetailsLazyQuery>;
export type GetCurrentAgentDetailsQueryResult = Apollo.QueryResult<GetCurrentAgentDetailsQuery, GetCurrentAgentDetailsQueryVariables>;
