import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editAgentSupervisor.graphql';

const defaultOptions = {} as const;
export type EditAgentSupervisorMutationVariables = Types.Exact<{
	data: Types.EditAgentInput;
}>;

export type EditAgentSupervisorMutation = {editAgent?: {id: string} | null};

export type EditAgentSupervisorType = {id: string};

export type EditAgentSupervisorMutationFn = Apollo.MutationFunction<EditAgentSupervisorMutation, EditAgentSupervisorMutationVariables>;

/**
 * __useEditAgentSupervisorMutation__
 *
 * To run a mutation, you first call `useEditAgentSupervisorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAgentSupervisorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAgentSupervisorMutation, { data, loading, error }] = useEditAgentSupervisorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditAgentSupervisorMutation(
	baseOptions?: Apollo.MutationHookOptions<EditAgentSupervisorMutation, EditAgentSupervisorMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditAgentSupervisorMutation, EditAgentSupervisorMutationVariables>(Operations, options);
}
export type EditAgentSupervisorMutationHookResult = ReturnType<typeof useEditAgentSupervisorMutation>;
export type EditAgentSupervisorMutationResult = Apollo.MutationResult<EditAgentSupervisorMutation>;
export type EditAgentSupervisorMutationOptions = Apollo.BaseMutationOptions<EditAgentSupervisorMutation, EditAgentSupervisorMutationVariables>;
