import {MoneyInput} from '@thx/controls';
import {isArray} from 'lodash-es';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Label, List, Input, Header, Checkbox} from 'semantic-ui-react';
import {GeneralLedgerAccountsDropdown} from '~common/components/GeneralLedgerAccountsDropdown';
import {useCommonState} from '~common/state';
import {useAccountState} from '../../../../accounts/state';
import {routes} from '../../../routes';
import {clearJournalEntriesFilter, setJournalEntriesFilter, useAccountingState} from '../../../state';

export function JournalEntriesSidebar() {
	const {accountInfoId} = useParams<typeof routes.types.journalEntries>();
	const {journalEntriesFilter, historicDataEntryMode, fiscalOpenDate} = useAccountingState();
	const {yearEnd} = useAccountState();
	const {endDate} = useCommonState();

	const {comment, showDetailed, glAccounts, fromAmount, toAmount} = journalEntriesFilter;
	const {push} = useHistory();
	const dispatch = useDispatch();

	if (historicDataEntryMode) {
		return null;
	}

	return (
		<List>
			{(!yearEnd || !fiscalOpenDate || (yearEnd && fiscalOpenDate && !endDate.isBefore(fiscalOpenDate))) && (
				<>
					<List.Item>
						<Button
							icon="add"
							content="Create Journal Entry"
							positive
							fluid
							onClick={() => push(routes.to.createGeneralJournalEntry({accountInfoId}))}
						/>
					</List.Item>
					<Divider />
				</>
			)}
			<Header size="small">Filter Journal Entries</Header>
			<List.Item>
				<Label>Comment</Label>
				<Input fluid placeholder="Comment" value={comment || ''} onChange={e => dispatch(setJournalEntriesFilter({comment: e.target.value}))} />
			</List.Item>
			<List.Item>
				<Label>GL Account</Label>
				<GeneralLedgerAccountsDropdown
					multiple
					accountInfoId={accountInfoId}
					placeholder="GL Account"
					clearable
					selection
					value={glAccounts || []}
					onChange={glAcc => {
						dispatch(setJournalEntriesFilter({glAccounts: glAcc && isArray(glAcc) && glAcc.length ? glAcc : undefined}));
					}}
				/>
			</List.Item>
			<List.Item>
				<Label>From Amount</Label>
				<MoneyInput
					fluid
					placeholder="From Amount"
					value={fromAmount}
					onChange={value => value && dispatch(setJournalEntriesFilter({fromAmount: value}))}
				/>
			</List.Item>
			<List.Item>
				<Label>To Amount</Label>
				<MoneyInput
					fluid
					placeholder="To Amount"
					value={toAmount}
					onChange={value => value && dispatch(setJournalEntriesFilter({toAmount: value}))}
				/>
			</List.Item>
			<List.Item>
				<Checkbox
					label="Display Revisions"
					checked={showDetailed}
					onChange={(e, checkbox) => dispatch(setJournalEntriesFilter({showDetailed: checkbox.checked}))}
				/>
			</List.Item>
			<List.Item>
				<Button content="Clear Selection" onClick={() => dispatch(clearJournalEntriesFilter())} />
			</List.Item>
		</List>
	);
}
