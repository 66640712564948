import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEditAgentOffice.graphql';

const defaultOptions = {} as const;
export type GetEditAgentOfficeQueryVariables = Types.Exact<{
	userId: Types.Scalars['String'];
}>;

export type GetEditAgentOfficeQuery = {
	getAgentByUserId?: {id: string; version: number; office?: {id: string} | null; organization?: {id: string} | null} | null;
};

export type GetEditAgentOfficeType = {id: string; version: number; office?: {id: string} | null; organization?: {id: string} | null};

/**
 * __useGetEditAgentOfficeQuery__
 *
 * To run a query within a React component, call `useGetEditAgentOfficeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditAgentOfficeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditAgentOfficeQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetEditAgentOfficeQuery(baseOptions: Apollo.QueryHookOptions<GetEditAgentOfficeQuery, GetEditAgentOfficeQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEditAgentOfficeQuery, GetEditAgentOfficeQueryVariables>(Operations, options);
}
export function useGetEditAgentOfficeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEditAgentOfficeQuery, GetEditAgentOfficeQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEditAgentOfficeQuery, GetEditAgentOfficeQueryVariables>(Operations, options);
}
export type GetEditAgentOfficeQueryHookResult = ReturnType<typeof useGetEditAgentOfficeQuery>;
export type GetEditAgentOfficeLazyQueryHookResult = ReturnType<typeof useGetEditAgentOfficeLazyQuery>;
export type GetEditAgentOfficeQueryResult = Apollo.QueryResult<GetEditAgentOfficeQuery, GetEditAgentOfficeQueryVariables>;
